export const venues = [
	{
		"id": 17,
		"photos": [
			"https://lh3.googleusercontent.com/R-EqabTZGxpWJ7U05vIjhmnjbvS_xl5Z1QYJu38CpM1pqcznuOBsrHznauiXpxi77FdeLDIjYoStrEt458yRijFw72uiaFbEPSaeN13v_YBO1dzmTazDc5TqwIc22OY-Gc5xHqC9VX4h51V1aeVwkC4-qRjzEzBMj3R-_EOM7vP19Y-R_B6X_pvpCJnasgqRhPFLGoR_4aDvtxTbsOLp12K458h4Q1Iz39Mt-FjKEDUgh5av-jZijs-8F1rbqqvjqCjQRxajTyAWi19W0LEnS8uim1v9ckKxppjiBuBZDKQJmqlro0VNJgvu06-j1mGW2z34wrAhhAfU3xvgWT1XsXA_sbp3Jn0EZSftjLko3lPho-Bhzg9OOCKX5bIuQRoRV0kdnIOickJJnqfuF6-RIr_07kYsu2LpY8JAseCE0dq9H5Z8zRaHYsIXfkJ3mF60G7HDknf1iR8CeyoSghgP_K2LidxR9fQoGMya5hgHNaeXGzJF45eqMV0YDqPtQMfykCRdMPW_zzw36BrsndLqB4eOTf9Rk5OGpFFUextPGZNK-oPd9q84wTlqwJoADStErm-YqK5XKj8I0lMY8llVvy_OXdeNubmhke2ikOstSn79DQ23xKAV6atGyDNXrbFZ1eiUY8DUAxZAu6gbGutF0GykwNVZvoCyVjPlX-RsRjkB1neEyp-140jXi4nk07Kyp2qDjFsS9pNu2nvBebO5SUBgwa2exsZ_GgohwOSf9eh8rNiBiMdY=w1400-h1050-no",
			"https://www.tryreason.com/wp-content/uploads/2020/04/rename-battery-1-scaled.jpg",
			"https://www.tryreason.com/wp-content/uploads/2020/04/ISS-dashboard-scaled.jpg",
			"https://www.tryreason.com/wp-content/uploads/2020/04/aux-power-scaled.jpg",
			"https://www.tryreason.com/wp-content/uploads/2020/04/cable-dark-scaled.jpg"

		],
		"name": "Lola In Space",
		"description": "Dial into the ISS via video conference. Lola is trapped in the supply module on the International Space Station while Mission Control in Houston is down. Can your team get in touch and help Lola escape?",
		"teaserDescription": "Team Escape Room",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://www.tryreason.com/lola-in-space/",
		"linkToBook": "https://tryreason.rezdy.com/?agentCode=tb",
		"relevantLinks": [],
		"activityType": "event",
		"tags": ["cooperative"],
		"rating": 4.8,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 6],
		"price": 30,
		"videoTitle": "",
		"videoLink": "",
		"priceReasoning": "Based on price per game",
		"symbol": "lola-space"
	},
	{
		"id": 15,
		"photos": [
			"https://netflixparty.s3.amazonaws.com/img/land/chat-display.svg"
		],
		"name": "Netflix Party",
		"description": "Netflix Party is a new way to watch Netflix with your friends online. Netflix Party synchronizes video playback and adds group chat to your favorite Netflix shows. Join over 1,000,000 people and use Netflix Party to link up with friends and host long distance movie nights and TV watch parties today!",
		"teaserDescription": "Social Netflix Watching",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://www.netflixparty.com/",
		"relevantLinks": [],
		"activityType": "hangout",
		"tags": ["relax", "movie"],
		"rating": 4.9,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 20],
		"price": 0,
		"videoTitle": "Trailer",
		"videoLink": "https://www.youtube.com/embed/m_B1YOIYM3Q",
		"priceReasoning": "Based on price per game",
		"symbol": "netflix-party"
	},
	{
		"id": 1,
		"photos": [
			"https://steamcdn-a.akamaihd.net/steam/apps/434170/capsule_616x353.jpg?t=1575565390",
			"https://steamcdn-a.akamaihd.net/steam/apps/331670/capsule_616x353.jpg?t=1575565258	",
			"https://is3-ssl.mzstatic.com/image/thumb/Purple62/v4/c8/76/13/c87613f9-2008-7721-7bc1-2716f711e761/pr_source.png/643x0w.png"
		],
		"name": "JackBoxGames",
		"description": "A small but mighty party game studio that has been making irreverent party games since 1995.",
		"teaserDescription": "Games of Many Varieties",
		"phoneNumber": "s",
		"email": "",
		"linkToSite": "https://www.jackboxgames.com/games/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["variety", "board game", "card game", "video game"],
		"rating": 4.7,
		"takesReservations": false,
		"averageTimeSpent": 30,
		"recommendedGroupsize": [2, 10],
		"price": 5,
		"videoTitle": "Trailer",
		"videoLink": "https://www.youtube.com/embed/uuPY0_8CO6o",
		"priceReasoning": "Based on price per game",
		"symbol": "jackbox"
	},
	{
		"id": 14,
		"photos": [
			"https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/ce/e2/ac/cee2aca5-dc52-a1e5-a2f5-ea6cf4ae3be9/pr_source.png/230x0w.png",
			"https://is5-ssl.mzstatic.com/image/thumb/Purple123/v4/b2/af/9d/b2af9d6e-3828-faa8-a634-21a987cc0cd1/pr_source.png/460x0w.png",
			"https://i.ibb.co/4mh727K/summer.jpg",
		],
		"name": "Glimpse",
		"description": "Randomly get paired with friends for 2 minutes in a room for your specific team or group.",
		"teaserDescription": "2 Minute Team 1:1s",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://www.joinglimpse.com/",
		"relevantLinks": [],
		"activityType": "hangout",
		"tags": ["fun", "social"],
		"rating": 4.8,
		"takesReservations": true,
		"averageTimeSpent": 20,
		"recommendedGroupsize": [2, 100],
		"price": 0,
		"videoTitle": "",
		"videoLink": "",
		"priceReasoning": "Based on price per app install",
		"symbol": "glimpse"
	},
	{
		"id": 25,
		"photos": [
			"https://mattle.online/assets/images/games/online-go.png",
			"https://mattle.online/assets/images/games/online-azul.png",
			"https://mattle.online/assets/images/games/online-seven-wonders-duel.png"
		],
		"name": "Mattle Games",
		"description": "Digital hosting of a variety of boardgames and cardgames including Go and Azee.",
		"teaserDescription": "Games of Many Varieties",
		"phoneNumber": "s",
		"email": "",
		"linkToSite": "https://mattle.online/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["variety", "board game", "card game"],
		"rating": 4.8,
		"takesReservations": false,
		"averageTimeSpent": 30,
		"recommendedGroupsize": [2, 10],
		"price": 0,
		"videoTitle": "",
		"videoLink": "",
		"priceReasoning": "Based on price per game",
		"symbol": "mattle"
	},
	{
		"id": 2,
		"photos": [
			"https://lh3.googleusercontent.com/tOhMr6zvwYuTOx6fHHJJfjOT7IJEV7U4x_kiJgYXA83EJ3PuoDc-9xwV4gHC9fkWpC_Oabji7iQciMbJAAsWT9wP-y5EGMnmBnTAM7DsSv0L4aH69KmqGhVzNInGptmtoah3Ln8A=w2400"
		],
		"name": "Playing Cards",
		"description": "Play any card game with friends. Use one of the standards games available, or create your own game.",
		"teaserDescription": "Card Games",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "http://playingcards.io/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["card game"],
		"rating": 4.8,
		"takesReservations": false,
		"averageTimeSpent": 30,
		"recommendedGroupsize": [2, 10],
		"price": 0,
		"videoTitle": "Demo",
		"videoLink": "https://www.youtube.com/embed/1BPap52PC5k",
		"priceReasoning": "Based on price per game",
		"symbol": "playing-cards"
	},
	{
		"id": 3,
		"photos": [
			"https://images.squarespace-cdn.com/content/v1/592b6fc18419c2e1dd16608b/1496582160099-VHQGDW7D2Y7V61QGEJ4D/ke17ZwdGBToddI8pDm48kJnhWAMtyaYSt0MZVUAWo5x7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UcZ0ASpjqwMUWQ78zgkNKzcnxiQbnGSSdPrTxfnMvA7y6zbdMayf9wmorX22cmxA1Q/Secret+Hitler+Board+Game+Components",
			"https://images-na.ssl-images-amazon.com/images/I/61-sgBQti5L._AC_SL1000_.jpg"
		],
		"name": "Secret Hitler",
		"description": "Secret Hitler is a dramatic game of political intrigue and betrayal set in 1930's Germany. Players are secretly divided into two teams - liberals and fascists. Known only to each other, the fascists coordinate to sow distrust and install their cold-blooded leader. The liberals must find and stop the Secret Hitler before it is too late.",
		"teaserDescription": "Dramatic Social Discovery Game",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://secrethitler.io/observe/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["cooperative", "competitive"],
		"rating": 4.9,
		"takesReservations": false,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [4, 10],
		"price": 0,
		"videoTitle": "Demo",
		"videoLink": "https://www.youtube.com/embed/nKVLNVEnpOk",
		"priceReasoning": "Based on price per game",
		"symbol": "secret-hitler"
	},
	{
		"id": 4,
		"photos": [
			"https://images-na.ssl-images-amazon.com/images/I/71UUDAPpKWL._AC_SX466_.jpg",
			"https://www.thespruce.com/thmb/YH0CfiJyH3Sab987GTo4E1wHwLs=/1500x1125/smart/filters:no_upscale()/ExplodingKittens_FamilyGames_HeroSquare-750928e54de54f138da023343ef93f62.jpg"
		],
		"name": "Exploding Kittens",
		"description": "Exploding Kittens is a highly strategic kitty-powered version of Russian Roulette. Players take turns drawing cards until someone draws an exploding kitten and loses the game.",
		"teaserDescription": "Silly Board Game",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://explodingkittens.com/mobile-app",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["board game"],
		"rating": 4.6,
		"takesReservations": false,
		"averageTimeSpent": 10,
		"recommendedGroupsize": [2, 5],
		"price": 2,
		"videoTitle": "Trailer and Gameplay",
		"videoLink": "https://www.youtube.com/embed/66Nm6rVQ-_8",
		"priceReasoning": "Based on price of app",
		"symbol": "exploding kittens"
	},
	{
		"id": 30,
		"photos": [
			"https://kahoot.com/files/2017/09/join_live_games_kahoot_app.jpg",
			"https://www.agclassroom.org/ok/resources/images/classroom/kahoot.jpg",
			"https://teachingwithipad.files.wordpress.com/2017/01/photo-jan-27-2017-313-pm.jpg"
		],
		"name": "Kahoot",
		"description": "Fun online trivia, quizzes, learning.",
		"teaserDescription": "Team Trivia/Learning",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://create.kahoot.it/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["trivia"],
		"rating": 4.7,
		"takesReservations": true,
		"averageTimeSpent": 20,
		"recommendedGroupsize": [2, 10],
		"price": 0,
		"videoTitle": "Demo",
		"videoLink": "https://www.youtube.com/embed/pAfnia7-rMk",
		"priceReasoning": "Based on price per game",
		"symbol": "kahoot"
	},
	{
		"id": 6,
		"photos": [
			"https://miro.medium.com/max/600/1*-Eq-EzO67N4DFTZJVpGqSg.gif",
			"https://miro.medium.com/max/1400/1*9k72ALg3clduGTkizilJ-A.jpeg"
		],
		"name": "Heads Up via Houseparty",
		"description": "Heads Up! is everyone’s favorite IRL party game — top 5 in app charts for the past 5 years! It’s fun, social, and can only be played together.",
		"teaserDescription": "Fun Face-Face Game",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://houseparty.com/",
		"relevantLinks": [
			"https://medium.com/@houseparty/heads-up-on-houseparty-cfdc3b2520f"
		],
		"activityType": "game",
		"tags": ["social"],
		"rating": 4.7,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 10],
		"price": 0,
		"videoTitle": "Trailer",
		"videoLink": "https://www.youtube.com/embed/Rc9l5g7oXX0",
		"priceReasoning": "Based on price per game",
		"symbol": "heads-up-house-party"
	},
	{
		"id": 7,
		"photos": [
			"https://target.scene7.com/is/image/Target/GUEST_847fae64-840e-4a43-af64-a5e2449688d7",
			"https://i1.wp.com/nowthatsthrifty.com/wp-content/uploads/2019/10/Scattergories-Lists-with-Free-Printables-Facebook-post.png?fit=1200%2C628&ssl=1",
			"https://usercontent2.hubstatic.com/14383503_f520.jpg"
		],
		"name": "Scattegories",
		"description": "Scattergories is a creative-thinking category-based party game. The objective of the 2-to-6-player game is to score points by uniquely naming objects within a set of categories, given an initial letter, within a time limit.",
		"teaserDescription": "Category Party Game",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://scattergoriesonline.net/new-game.xhtml",
		"relevantLinks": [
			"https://apps.apple.com/us/app/scattergories/id1011376303",
			"https://play.google.com/store/apps/details?id=com.magmic.android.squash&hl=en_US"
		],
		"activityType": "game",
		"tags": ["category game"],
		"rating": 4.2,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 10],
		"price": 0,
		"videoTitle": "",
		"videoLink": "",
		"priceReasoning": "Based on price per game",
		"symbol": "scattegories"
	},
	{
		"id": 8,
		"photos": [
			"https://i.ytimg.com/vi/wZ_UJxp5JYA/maxresdefault.jpg",
			"https://mkt-web.gameloft.com/static/32bdd65fa7462d87e23163095084d295.png"
		],
		"name": "Song Pop",
		"description": "The world's largest music trivia community! Listen to actual songs and guess as fast as you can, featuring music from all your favorite artists- whether Taylor Swift, Drake, Selena Gomez, and Justin Bieber or Van Halen, Queen, Ed Sheeran and more! Challenge your friends and play against music lovers worldwide!",
		"teaserDescription": "Music Trivia",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://www.songpop2.com/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["music", "trivia"],
		"rating": 4.3,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 10],
		"price": 5,
		"videoTitle": "Demo",
		"videoLink": "https://www.youtube.com/embed/Wna241zBN2I",
		"priceReasoning": "Based on price per month",
		"symbol": "song-pop"
	},
	{
		"id": 32,
		"photos": [
			"https://i.redd.it/113nu2z42qh21.png",
			"https://miro.medium.com/max/2069/1*jQRplWPgZc6m1LuvIQWx7g.png"
		],
		"name": "Netgames.io",
		"description": "A website that supports and hosts a variety of social deduction games including werewolf, secret hitler, and codenames.",
		"teaserDescription": "Social Deduction Games",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://netgames.io/games/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["immersive", "fun", "deceit"],
		"rating": 4.8,
		"takesReservations": true,
		"averageTimeSpent": 30,
		"recommendedGroupsize": [4, 10],
		"price": 0,
		"videoTitle": "Demo",
		"videoLink": "https://www.youtube.com/embed/azGwqntHJfQ",
		"priceReasoning": "Based on price per game",
		"symbol": "net-games"
	},
	{
		"id": 9,
		"photos": [
			"https://www.catan.com/files/styles/lightboxy/public/gallery/screenshot_mobile_6.png?itok=jLlrC5Hg",
			"https://steamcdn-a.akamaihd.net/steam/apps/544730/ss_7331ef649ed032036f7fd93a6a1f23f04300daa9.1920x1080.jpg?t=1572340135",
			"https://www.theboardgamefamily.com/wp-content/uploads/2018/06/CatanUniverseLogo-200x157.jpeg"
		],
		"name": "Settlers of Catan",
		"description": "Board game where players take on the roles of settlers, each attempting to build and develop holdings while trading and acquiring resources. Players gain points as their settlements grow; the first to reach a set number of points, typically 10, wins.",
		"teaserDescription": "Classic Board Game",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://catanuniverse.com/en/game/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["board game", "strategy"],
		"rating": 4.6,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 6],
		"price": "0-20",
		"videoTitle": "Trailer",
		"videoLink": "https://www.youtube.com/embed/9LQdidVUa1U",
		"priceReasoning": "Based on price per game depending on platform",
		"symbol": "catan"
	},
	{
		"id": 31,
		"photos": [
			"https://www.quizwitz.com/images/banner/ted.png",
			"https://www.quizwitz.com/images/slides/QW_screen_3.jpg",
			"https://www.quizwitz.com/images/slides/QW_screen_4.jpg"
		],
		"name": "Quiz Whitz",
		"description": "Fun online community trivia with leaderboards.",
		"teaserDescription": "Team Trivia",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://www.quizwitz.com/en/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["trivia"],
		"rating": 4.7,
		"takesReservations": true,
		"averageTimeSpent": 20,
		"recommendedGroupsize": [2, 10],
		"price": 0,
		"videoTitle": "Trailer",
		"videoLink": "https://www.youtube.com/embed/Px23d-OZDY8",
		"priceReasoning": "Based on price per game",
		"symbol": "quiz-whitz"
	},
	{
		"id": 19,
		"photos": [
			"https://teambuilding.com/wp-content/uploads/2020/04/Online-Office-Games.jpg",
			"https://teambuilding.com/wp-content/uploads/2020/03/tiny-campfire.jpg",
			"https://teambuilding.com/wp-content/uploads/2020/03/tea-coffee.jpg"
		],
		"name": "TeamBuilding",
		"description": "Online Office Games is a mashup of office competitions for remote workers. These virtual games are designed to create friendships, forge bonds, build skills and inspire strong team work. Like real-world sporting events, this online version is both competitive and collaborative.",
		"teaserDescription": "Instructor Led Games",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://teambuilding.com/events/online-office-games#details",
		"relevantLinks": [],
		"activityType": "event",
		"tags": ["fun"],
		"rating": 4.4,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 20],
		"price": "varies",
		"videoTitle": "",
		"videoLink": "",
		"priceReasoning": "Based on price per game",
		"symbol": "teambuilding"
	},
	{
		"id": 26,
		"photos": [
			"https://colonist.io/dist/images/logo.png"
		],
		"name": "Colonist",
		"description": "Board game where players take on the roles of settlers, each attempting to build and develop holdings while trading and acquiring resources. Players gain points as their settlements grow; the first to reach a set number of points, typically 10, wins.",
		"teaserDescription": "Settlers of Catan",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://colonist.io/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["board game", "strategy"],
		"rating": 4.6,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 6],
		"price": "0",
		"videoTitle": "Tutorial",
		"videoLink": "https://www.youtube.com/embed/-oHvONNjxYw",
		"priceReasoning": "Based on price per game depending on platform",
		"symbol": "colonist"
	},
	{
		"id": 10,
		"photos": [
			"https://static.wixstatic.com/media/efee5f_caf5300e158c4c1ab281f30e2de4a4f4~mv2.png/v1/fill/w_252,h_450,al_c,q_85,usm_0.66_1.00_0.01/efee5f_caf5300e158c4c1ab281f30e2de4a4f4~mv2.webp",
			"https://static.wixstatic.com/media/efee5f_4e3a313f619a4c699dc60eed3a769f6f~mv2.png/v1/fill/w_252,h_450,al_c,q_85,usm_0.66_1.00_0.01/efee5f_4e3a313f619a4c699dc60eed3a769f6f~mv2.webp"
		],
		"name": "Pokerrrr 2",
		"description": "Pokerrrr 2 is the ultimate multiplayer poker app to play with your buddies! It's as simple as creating a free private room, setting the rules( No Limit Hold'em, OFC - Open face Chinese poker, Short Deck, Pot-Limit Omaha, Round of Each, Sit-and-go, MTT, Tournament Timer and so on) and dealing out the cards.",
		"teaserDescription": "Poker",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://www.pokerrrrapp.com/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["poker", "card game"],
		"rating": 4.7,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 9],
		"price": 0,
		"videoTitle": "Old Demo",
		"videoLink": "https://www.youtube.com/embed/IrLIQvXv54M",
		"priceReasoning": "Based on price per game",
		"symbol": "pokerrr"
	},
	{
		"id": 11,
		"photos": [
			"https://cdn-mariokarttour.akamaized.net/stylesheets/images/FPHZKn4xDb5HK_BRaX-AI3ksa5Kn5vsdn_zONKN0qq.png",
			"https://pbs.twimg.com/profile_images/1236853272360529920/BV7oo29e_400x400.png",
			"https://i1.wp.com/metro.co.uk/wp-content/uploads/2019/09/Mobile_MarioKartTour_01-3eb4.jpg?quality=90&strip=all&zoom=1&resize=644%2C664&ssl=1"
		],
		"name": "Mario Kart Tour",
		"description": "Mario Kart is a series of go-kart-style racing video games developed and published by Nintendo as spin-offs from its trademark Super Mario series.",
		"teaserDescription": "Plaful Racing",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://mariokarttour.com/en-US",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["video game"],
		"rating": 4.5,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 9],
		"price": 0,
		"videoTitle": "Trailer",
		"videoLink": "https://www.youtube.com/embed/L0CnvKLNCGE",
		"priceReasoning": "Based on price per game",
		"symbol": "mario-kart"
	},
	{
		"id": 12,
		"photos": [
			"https://www.androidpolice.com/wp-content/uploads/2017/03/nexus2cee_clue.png",
			"https://i.ytimg.com/vi/VtqLqeTlgCA/maxresdefault.jpg"
		],
		"name": "Clue",
		"description": "In this suspenseful Clue game, players have to find out who's responsible for murdering Mr. Boddy of Tudor Mansion in his own home.",
		"teaserDescription": "Suspenful Board Game",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://apps.apple.com/us/app/clue-the-classic-mystery-game/id1150534552",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["mobile game"],
		"rating": 4.2,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 6],
		"price": 4,
		"videoTitle": "Trailer",
		"videoLink": "https://www.youtube.com/embed/3GHK4ruLdW8",
		"priceReasoning": "Based on price per game",
		"symbol": "clue"
	},
	{
		"id": 14,
		"photos": [
			"https://icebreaker.video/img/home/how-it-works-gather@2x.png",
			"https://icebreaker.video/img/home/how-it-works-video-chat@2x.png"
		],
		"name": "IceBreaker Video",
		"description": "Engaging series of mini-questions and mini-pairings of people to discuss and get to know one another better.",
		"teaserDescription": "Icebreaker Video",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://icebreaker.video/",
		"relevantLinks": [],
		"activityType": "hangout",
		"tags": ["fun", "social"],
		"rating": 4.8,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 100],
		"price": 0,
		"videoTitle": "",
		"videoLink": "",
		"priceReasoning": "Based on price per game",
		"symbol": "icebreaker"
	},
	{
		"id": 16,
		"photos": [
			"https://i.ytimg.com/vi/SxKy2lrGBBQ/maxresdefault.jpg",
			"https://steamcdn-a.akamaihd.net/steam/apps/488950/ss_a016ce5312078d42c243607f5d7928546f80a9d2.1920x1080.jpg?t=1501690209"
		],
		"name": "Werewolf",
		"description": "After several attacks on townsfolk, the medieval village of Gallowston has had enough. With a special guidebook in hand, players congregate to vote and eliminate any and all possible werewolves hiding disguised in the village. Players utilize specific roles and abilities, and the power of persuasion to assess, deduce, and win the round.",
		"teaserDescription": "Fast Paced Social Game",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://www.ubisoft.com/en-us/game/werewolves-within/",
		"relevantLinks": [
			"https://www.virtualrealityrental.co/blog-post/top-ten-virtual-reality-team-building-experiences"
		],
		"activityType": "vr",
		"tags": ["immersive", "fun"],
		"rating": 4.8,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [4, 10],
		"price": 20,
		"videoTitle": "Trailer",
		"videoLink": "https://www.youtube.com/embed/SxKy2lrGBBQ",
		"priceReasoning": "Based on price per game",
		"symbol": "werewolf"
	},
	{
		"id": 21,
		"photos": [
			"https://cf.geekdo-images.com/opengraph/img/KvWkw6TGLr4arA4E1lwnJocdy8E=/fit-in/1200x630/pic2582929.jpg",
			"https://www.jbowens.org/static/images/codenames-spymaster-view.png"
		],
		"name": "Codenames",
		"description": "Two teams compete by each having a 'spymaster' give one-word clues that can point to multiple words on the board. The other players on the team attempt to guess their team's words while avoiding the words of the other team. In a variant with 2–3 players, one spymaster gives clues to the other player or players.",
		"teaserDescription": "Game Using Secret Words",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://www.horsepaste.com/",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["easy", "fun"],
		"rating": 4.3,
		"takesReservations": false,
		"averageTimeSpent": 30,
		"recommendedGroupsize": [2, 6],
		"price": 0,
		"videoTitle": "Tutorial",
		"videoLink": "https://www.youtube.com/embed/TusM9kevpRA",
		"priceReasoning": "Based on price per game",
		"symbol": "horsepaste"
	},
	{
		"id": 20,
		"photos": [
			"https://media-assets-03.thedrum.com/cache/images/thedrum-prod/s3-houseparty3--default--1280.png",
			"https://cdn.comparitech.com/wp-content/uploads/2018/09/houseparty3-1024x495.jpg"
		],
		"name": "House Party",
		"description": "Houseparty is a social networking service that enables group video chatting through mobile and desktop apps. Users receive a notification when friends are online and available to group video chat. On average, users spend approximately 51 minutes a day on the app in group or one-on-one chats.",
		"teaserDescription": "Face to Face Social Network",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://houseparty.com/",
		"relevantLinks": [],
		"activityType": "hangout",
		"tags": ["chill", "fun"],
		"rating": 4.5,
		"takesReservations": false,
		"averageTimeSpent": 51,
		"recommendedGroupsize": [2, 10],
		"price": 0,
		"videoTitle": "",
		"videoLink": "",
		"priceReasoning": "App is free",
		"symbol": "hous-party"
	},
	{
		"id": 27,
		"photos": [
			"http://jqbx.fm/images/jqbx.png",
			"http://jqbx.fm/images/intro-new.png",
			"http://jqbx.fm/images/chat.jpg"
		],
		"name": "JQBX FM",
		"description": "JQBX lets you be a DJ, join a party, or just kick back and listen to music with friends or strangers from all over the world in real time. JQBX hooks into your Spotify account and is 100% free to use. Give it a try and start listening. Together.",
		"teaserDescription": "Listen To Music With Friends",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "http://jqbx.fm/",
		"relevantLinks": [],
		"activityType": "hangout",
		"tags": ["chill", "fun", "music"],
		"rating": 4.6,
		"takesReservations": false,
		"averageTimeSpent": 15,
		"recommendedGroupsize": [2, 10],
		"price": 0,
		"videoTitle": "Demo",
		"videoLink": "https://www.youtube.com/embed/ww2ChsirdE0",
		"priceReasoning": "App is free",
		"symbol": "jqbx"
	},
	{
		"id": 29,
		"photos": [
			"https://town.siempre.io/images/site/hn-cleaned.png",
			"https://town.siempre.io/images/site/xr1.png"
		],
		"name": "Online Town",
		"description": "Online Town lets you setup a virtual town where you can hang out virtually as an avatar with friends on a variety of town landscapes.",
		"teaserDescription": "Virtual Hangout",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://town.siempre.io/",
		"relevantLinks": [],
		"activityType": "hangout",
		"tags": ["chill"],
		"rating": 4.4,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 10],
		"price": 0,
		"videoTitle": "",
		"videoLink": "",
		"priceReasoning": "Based on price per game",
		"symbol": "onlin-town"
	},
	{
		"id": 5,
		"photos": [
			"https://images.techhive.com/images/article/2014/03/quizup2-100248921-large.jpg",
			"https://quizup-web.s3.amazonaws.com/logos/logos-1200x630-store-buttons-centered-2014-12-10/quizup-logo-1200x630-2014-12-10/quizup-logo-1200x630-centered.png",
			"https://cnet1.cbsistatic.com/img/fRcMQuCkbD3Ijo3iILBf-8rlf0A=/470x376/2014/03/19/6a1c3b10-b0c6-11e3-a24e-d4ae52e62bcc/QuizUp_Home_Activity.png"
		],
		"name": "Quiz Up",
		"description": "Fun, addictive, challenging, online trivia, that has the best of two worlds.",
		"teaserDescription": "Team Trivia",
		"phoneNumber": "",
		"email": "",
		"linkToSite": "https://www.quizup.com/en",
		"relevantLinks": [],
		"activityType": "game",
		"tags": ["trivia"],
		"rating": 4.6,
		"takesReservations": true,
		"averageTimeSpent": 60,
		"recommendedGroupsize": [2, 10],
		"price": 0,
		"videoTitle": "",
		"videoLink": "",
		"priceReasoning": "Based on price per game",
		"symbol": "quiz-up"
	}
	// {
	// 	"id": 18,
	// 	"photos": [
	// 		"https://uploads-ssl.webflow.com/5e715b7883e9f1e1fdda73b5/5e8387a1cbca0ebcdd38014a_Magic%20Screen%20Shot%20(Hands%20in%20the%20air)-p-1080.png"
	// 	],
	// 	"name": "Speakeasy",
	// 	"description": "Speakeasy is an early-stage startup offering intimate online events with compelling speakers and performers. Their vision is to bring the vibrancy of a university campus to people everywhere.",
	// 	"teaserDescription": "Online Talks and Workshops",
	// 	"phoneNumber": "",
	// 	"email": "",
	// 	"linkToSite": "https://www.speakeasy.com/",
	// 	"relevantLinks": [],
	// 	"activityType": "event",
	// 	"tags": ["listen", "learn"],
	// 	"rating": 4.7,
	// 	"takesReservations": true,
	// 	"averageTimeSpent": 60,
	// 	"recommendedGroupsize": [2, 20],
	// 	"price": "varies",
	// 	"videoTitle": "",
	// 	"videoLink": "",
	// 	"priceReasoning": "Based on price per game",
	// 	"symbol": "speakeasy"
	// },
]
